import React, { useRef } from "react";
import PropTypes from "prop-types";
import Button from "../ui/button";
import { useFieldFocus } from "../../hooks/signature-focus-context";
import { useFormikContext } from "formik";
import { scrollToElement } from "../../utils";
import "./signature-input.scss";

const SignatureInput = ({ id, pos, size, signatureData, attributes }) => {
  const { isFocused, focusElement } = useFieldFocus({
    id,
    attributes
  });
  const { values, setFieldValue } = useFormikContext();
  const elemRef = useRef();
  React.useEffect(() => {
    if (isFocused && elemRef && elemRef.current) {
      scrollToElement(elemRef.current);
    }
  }, [isFocused, elemRef]);
  return (
    <div
      className={`preview-item signature-element ${isFocused ? "focused" : ""}`}
      style={{ paddingTop: 0 }}
      onClick={() => {
        focusElement(id);
      }}
    >
      <input
        type="checkbox"
        ref={elemRef}
        onFocus={e => {
          focusElement(id);
        }}
        name={id}
        id={id}
        value={values[id]}
        style={{
          opacity: "0"
        }}
      />
      <div
        style={{
          width: size.absoluteWidth,
          height: size.absoluteHeight,
          margin: `${size.absoluteTop + 35} 0 0 ${size.absoluteLeft}`
          // borderBottom: attributes.underline ? "1px solid #333" : "none",
        }}
        className={`signature-input ${attributes.underline ? "underline" : ""}`}
      >
        {!values[id] ? (
          <div>
            <div
              className="button primary"
              onClick={() => {
                setFieldValue(id, signatureData);
              }}
            >
              <span>Signature</span>
            </div>
          </div>
        ) : (
          <div className="signed-container">
            <img
              id={id}
              src={values[id]}
              alt="Signed"
              className="signed-signature-img"
              style={{
                backgroundSize: "contain",
                display: "block",
                width: "100%",
                height: "100%"
              }}
            />
            <Button
              className={`danger signature-clear-btn no-print ${
                isFocused ? "visible" : "hidden"
              }`}
              onClick={() => setFieldValue(id, "")}
              style={{ visible: isFocused ? "visible" : "hidden" }}
            >
              Clear
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

SignatureInput.propTypes = {
  id: PropTypes.string.isRequired,
  signatureData: PropTypes.string.isRequired,
  value: PropTypes.string
};

export default SignatureInput;

import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { Formik } from "formik";
import ErrorPage from "@components/error-view";
import * as yup from "yup";
import { setTemplateInfo } from "@redux/template-info";
import { useDispatch } from "react-redux";
import { Button, Form, Dimmer, Loader } from "semantic-ui-react";
import Loading from "@components/ui/loading";
import { useMutation } from "react-query";
import useTokenValidation from "@hooks/useTokenValidation";

const UploadDocumentSchema = yup.object().shape({
  title: yup.string().required(),
  file: yup.object().isRequired,
});

const CreateSignatureTemplate = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const history = useHistory();
  const dispatch = useDispatch();
  useTokenValidation(params.token);

  const uploadSignatureTemplateApi = useMutation(async ({ values, params }) => {
    var formData = new FormData();
    formData.append("pdf_file", values.file);
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("token", params.token);
    formData.append("company", params.company);

    const response = await fetch("/api/template/upload-template", {
      method: "POST",
      headers: { Authorization: `Bearer ${params.token}` },
      body: formData,
    });
    if (response.ok) {
      const templateInfo = await response.json();
      dispatch(
        setTemplateInfo({
          ...params,
          ...templateInfo,
          loaded: true,
        })
      );
      history.push({
        pathname: `/template-editor?token=${params.token}&template_id=${templateInfo.template_id}&company=${params.company}`,
        state: { ...params },
      });
      return templateInfo;
    }
    var error = await response.text();
    throw error;
  });

  return (
    <div
      style={{
        padding: 5,
        flex: 1,
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {uploadSignatureTemplateApi.isLoading && <Loading />}
      {uploadSignatureTemplateApi.isError ? (
        <ErrorPage errorMessage={uploadSignatureTemplateApi.error} />
      ) : (
        <Formik
          initialValues={{ description: "", title: "", file: null }}
          onSubmit={async (values) => {
            await uploadSignatureTemplateApi.mutate({
              values,
              params,
            });
          }}
          validationSchema={UploadDocumentSchema}
        >
          {({
            isSubmitting,
            setFieldValue,
            handleChange,
            values,
            handleSubmit,
          }) => (
            <Form
              style={{
                minWidth: 400,
                border: "1px solid #ccc",
                borderRadius: 5,
                background: "#f5f5f5",
                padding: 10,
              }}
              onSubmit={handleSubmit}
            >
              <h1
                style={{
                  textAlign: "center",
                  fontFamily:
                    'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif',
                }}
              >
                Create Signature Template
              </h1>
              <Form.Field>
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Template Title"
                  value={values.title}
                  onChange={handleChange("title")}
                  required
                />
              </Form.Field>
              <Form.Field style={{ marginTop: 20 }}>
                <label>Description</label>
                <input
                  type="text"
                  placeholder="Template Description"
                  value={values.description}
                  onChange={handleChange("description")}
                  required
                />
              </Form.Field>
              <Form.Field style={{ marginTop: 30 }}>
                <label>Pdf File</label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 400,
                  }}
                >
                  <input
                    accept={".pdf"}
                    id="file"
                    type="file"
                    required
                    onChange={({ target }) => {
                      setFieldValue("file", target.files[0]);
                      //   const MBSize = event.target.files[0].size / 1024;
                      //   if (MBSize > 101376) {
                      //     errorMessage("File size must be less than 99MB.");
                      //   } else
                      // setSelectedFile(target.files[0]);
                    }}
                  />
                  <small style={{ paddingTop: 5 }}>
                    <label style={{}}>File size should be less than 99MB</label>
                  </small>
                </div>
                <Dimmer active>
                  <Loader active />
                </Dimmer>
                <Loader active />
              </Form.Field>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-start",
                  marginTop: 20,
                }}
              >
                {isSubmitting && <Loading />}
                <Button type="submit" primary>
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default CreateSignatureTemplate;

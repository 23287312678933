import React from "react";
import Loading from "@components/ui/loading";
import ErrorPage from "@components/error-view";
import { pick } from "ramda";
import SignatureScreen from "@components/sign-document/sign-document";
import { useQuery, useMutation } from "react-query";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import "./style.scss";

const SignPatientDocument = () => {
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);

  const templateInfoApi = useQuery(["meta", params], async () => {
    try {
      const response = await fetch(
        `/api/template/download/${params.template_id}/meta.json?token=${params.token}`,
      );
      if (!response.ok) throw new Error("Error fetching template info");
      const data = await response.json();
      return { ...params, ...data };
    } catch (err) {
      history.push({
        pathname: "/error-page",
        state: { errorMessage: "Invalid Link", showBackBtn: false },
      });
    }
  });

  const layoutApi = useQuery(["layout", params], async () => {
    try {
      const response = await fetch(
        `/api/template/download/${params.template_id}/layout.json?token=${params.token}`,
      );
      if (!response.ok) throw new Error("Error fetching template info");
      const data = await response.json();
      return data;
    } catch (err) {
      history.push({
        pathname: "/error-page",
        state: { errorMessage: "Invalid Link", showBackBtn: false },
      });
    }
  });

  const templateInfo = templateInfoApi.data ?? {};

  const saveSignatureApi = useMutation(
    async ({ formValues, layout, templateInfo }) => {
      const layoutWithValues = layout.map((x) => ({
        ...x,
        value: String(formValues[x.id]),
      }));
      const signatureData = {
        layout: layoutWithValues,
        template_id: templateInfo.template_id,
        id: templateInfo.signature_id,
        title: templateInfo.title ?? "title",
        description: "signed patient document",
        ...pick(
          [
            "font",
            "patient_initials",
            "patient_name",
            "signature",
            "signed_date",
          ],
          formValues,
        ),
        signature: "",
      };
      const response = await fetch("/api/signature/save", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${params.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(signatureData),
      });
      if (!response.ok) throw response.text();
      return response;
    },
  );
  if (
    layoutApi.isFetching ||
    templateInfoApi.isFetching ||
    saveSignatureApi.isLoading
  )
    return <Loading />;
  else if (
    layoutApi.isError ||
    templateInfoApi.isError ||
    saveSignatureApi.isError
  )
    return (
      <ErrorPage
        errorMessage={
          layoutApi.error?.message +
          "" +
          templateInfoApi.error?.message +
          "" +
          saveSignatureApi.error?.message
        }
      />
    );
  else if (saveSignatureApi.isSuccess)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "calc(100vh - 80px)",
          padding: "0 15px",
        }}
      >
        <h1
          style={{
            minHeight: "1rem",
            fontSize: "2rem",
            lineHeight: "1.28571429em",
            margin: "calc(2rem - .14286em) 0 1rem",
            fontWeight: "700",
            marginBottom: 50,
          }}
        >
          Thank you, your document has submitted successfully.
        </h1>
        <p
          style={{
            textAlign: "center",
            margin: "0 0 1em",
            lineHeight: "1.4285em",
            marginBottom: 10,
          }}
        >
          Please close this window.
        </p>
        <button
          className="button primary"
          type="button"
          onClick={() => {
            window.close();
          }}
        >
          Close
        </button>
      </div>
    );
  else
    return (
      <SignatureScreen
        handleSave={(formValues) => {
          console.log("save");
          saveSignatureApi.mutate({
            formValues,
            layout: layoutApi.data ?? [],
            templateInfo,
          });
        }}
        templateInfo={templateInfo}
        layout={layoutApi.data ?? []}
        preview
      />
    );
};

export default SignPatientDocument;

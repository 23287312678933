import PrintSignedDocumentScreen from "./print-view";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useQuery } from "react-query";
import Loading from "../ui/loading";
import "./style.scss";

const PrintDocumentView = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const templateInfoApi = useQuery(["meta", params], async () => {
    const response = await fetch(
      `/api/template/download/${params.template_id}/meta.json?token=${params.token}`
    );
    const data = await response.json();
    return { ...params, ...data };
  });

  const signedLayoutApi = useQuery(["signed", params], async () => {
    const response = await fetch(
      `/api/template/download/${params.template_id}/${params.signature_id}_signed.json?token=${params.token}`
    );
    const result = await response.json();
    return result;
  });

  const initialValues =
    signedLayoutApi.data?.reduce((prev, cur) => {
      return { [cur.id]: cur.value, ...prev };
    }, {}) ?? {};
  return templateInfoApi.isLoading || signedLayoutApi.isLoading ? (
    <div>
      <Loading />
    </div>
  ) : (
    <PrintSignedDocumentScreen
      layout={signedLayoutApi.data ?? {}}
      templateInfo={templateInfoApi.data ?? {}}
      initialValues={initialValues}
    />
  );
};

export default PrintDocumentView;

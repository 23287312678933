import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import SignDocument from "./components/sign-document";
import PrintView from "./components/print-view";
import CreateTemplate from "./components/create-template";
import DevelopmentUI from "./development";
import TemplateEditor from "./components/template-editor";
import TemplatePreview from "./components/template-preview";
import NotAuthorized from "./components/not-authorized";
import ErrorPage from "./components/error-page";
import OldSignatureUrlHandler from "./components/OldSignatureUrlHandler";
import NotFoundPage from "./components/not-found";
import { QueryClient, QueryClientProvider } from "react-query";
import "./app.scss";
import "./custom.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export default class App extends Component {
  static displayName = App.name;
  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <div>
          <Switch>
            <Route path="/template-preview*">
              <TemplatePreview />
            </Route>
            <Route path="/template-editor*">
              <TemplateEditor />
            </Route>
            {/* <Route path="/signature-view*"> */}
            {/*   <SignatureView /> */}
            {/* </Route> */}
            <Route path="/print-view*">
              <PrintView />
            </Route>
            <Route path="/create-template*" component={CreateTemplate} />
            <Route path="/signature*" component={SignDocument} />
            {/* index.php?route=common/patient&token=${encoded_token}&html_document_template_id=${sig.html_document_template_id}&patient_id=${user_id} */}
            {/* -                    //   &assignment_id=${sig.signature_assignment_id}&platform=web` */}
            <Route path="/index*" component={OldSignatureUrlHandler} />
            <Route path="/not-authorized">
              <NotAuthorized />
            </Route>
            <Route path="/error-page" exact>
              <ErrorPage />
            </Route>
            <Route path="/dev*" exact>
              <DevelopmentUI />
            </Route>
            <Route path="/print-view">
              <PrintView />
            </Route>
            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </div>
      </QueryClientProvider>
    );
  }
}

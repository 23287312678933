import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@components/ui/button";
import { clearLayout, selectLayout } from "@redux/layout";
import { selectTemplateInfo, setCurrentPage } from "@redux/template-info";
import Loading from "@components/ui/loading";
import Modal from "@components/ui/modal";
import { useMutation } from "react-query";
import ErrorPage from "@components/error-view";
import "./style.scss";

const FormControls = ({ onDragStart, onDragEnd }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const layout = useSelector(selectLayout);
  const templateInfo = useSelector(selectTemplateInfo);

  const saveSignatureFormLayoutApi = useMutation(
    async ({ templateInfo, layout }) => {
      const payload = {
        layout: layout.map((element) => ({
          ...element,
          attributes: {
            ...element.attributes,
            placeHolder: "",
            background: "",
          },
        })),
        company: "",
        patients: [],
        ...templateInfo,
      };

      const response = await fetch("/api/template/save-template", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${templateInfo.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        const errorMessage = await response.text();
        throw errorMessage;
      }
      const result = await response.json();
      return result;
    }
  );

  const { currentPage, pages } = templateInfo;

  return (
    <div className="form-controls no-print">
      {saveSignatureFormLayoutApi.isLoading && <Loading />}
      <Modal isShowing={saveSignatureFormLayoutApi.isError} fullscreen>
        <ErrorPage errorMessage={saveSignatureFormLayoutApi.error} />
      </Modal>
      <Modal isShowing={saveSignatureFormLayoutApi.isSuccess} fullscreen>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "calc(100vh - 80px)",
            padding: "0 15px",
          }}
        >
          <h1
            style={{
              minHeight: "1rem",
              fontSize: "2rem",
              lineHeight: "1.28571429em",
              margin: "calc(2rem - .14286em) 0 1rem",
              fontWeight: "700",
              marginBottom: 50,
            }}
          >
            Thank you, your document has submitted successfully.
          </h1>
          <p
            style={{
              textAlign: "center",
              margin: "0 0 1em",
              lineHeight: "1.4285em",
              marginBottom: 10,
            }}
          >
            Please close this window.
          </p>
          <button
            className="button primary"
            onClick={() => {
              window.close();
            }}
          >
            Close
          </button>
        </div>
      </Modal>
      <div className="app-header">S3 Signature Form Builder</div>
      <div className="form-controls-list">
        <div
          draggable
          onDragStart={(event) => onDragStart(event, "TEXT")}
          onDragEnd={onDragEnd}
          className="form-control draggable TEXT"
        >
          Text Input
        </div>
        <div
          onDragStart={(event) => onDragStart(event, "LABEL")}
          onDragEnd={onDragEnd}
          draggable
          className="form-control draggable LABEL"
        >
          Label
        </div>
        <div
          onDragStart={(event) => onDragStart(event, "SIGNATURE")}
          onDragEnd={onDragEnd}
          draggable
          className="form-control draggable SIGNATURE"
        >
          Signature
        </div>
        <div
          onDragStart={(event) => onDragStart(event, "DATE")}
          onDragEnd={onDragEnd}
          draggable
          className="form-control draggable DATE"
        >
          Date
        </div>
        <div
          onDragStart={(event) => onDragStart(event, "INITIALS")}
          onDragEnd={onDragEnd}
          draggable
          className="form-control draggable INITIALS"
        >
          Initials
        </div>
        <div
          onDragStart={(event) => onDragStart(event, "CHECKBOX")}
          onDragEnd={onDragEnd}
          draggable
          className="form-control draggable CHECKBOX"
        >
          Check Box
        </div>
        <div
          onDragStart={(event) => onDragStart(event, "RADIO_GROUP")}
          onDragEnd={onDragEnd}
          draggable
          className="form-control draggable RADIO_GROUP"
        >
          Radio Group
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-around",
          alignItems: "center",
          flexDirection: "row",
          height: 50,
          maxHeight: 50,
        }}
      >
        <Button
          onClick={() => {
            dispatch(clearLayout());
          }}
          className="danger"
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            history.push("/template-preview");
          }}
        >
          Preview
        </Button>
        <Button
          className="primary"
          onClick={async () => {
            if (templateInfo.patients) {
              alert("save and assign template to patients");
            } else {
              // var tempTemplateInfo = JSON.parse(JSON.stringify(templateInfo));
              // var tempLayout = JSON.parse(JSON.stringify(layout));
              // tempTemplateInfo.layout = tempLayout;
              //
              // tempTemplateInfo.patients = [];
              // tempTemplateInfo.company_id = "";
              // for (var i = 0; i < tempLayout.length; i++) {
              //   tempLayout[i].attributes.placeHolder = "";
              //   tempLayout[i].attributes.background = "";
              // }
              // const result = await saveSignatureFormLayout({
              //   tempLayout,
              //   ...tempTemplateInfo,
              // });
              await saveSignatureFormLayoutApi.mutate({
                templateInfo,
                layout,
              });
            }
          }}
          disabled={saveSignatureFormLayoutApi.isLoading}
        >
          Save
        </Button>
      </div>
      <div
        style={{
          display: "grid",
          marginTop: 10,
          gridTemplateColumns: "auto auto auto",
          gridGap: 5,
        }}
      >
        <Button
          onClick={() => {
            dispatch(setCurrentPage(currentPage - 1));
          }}
          disabled={templateInfo.currentPage <= 0}
        >
          Previous
        </Button>
        <span>
          Page: {currentPage + 1} / {pages}
        </span>
        <Button
          onClick={() => {
            dispatch(setCurrentPage(currentPage + 1));
          }}
          disabled={currentPage + 1 === pages}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

FormControls.propTypes = {
  onDragEnd: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
};

export default FormControls;

import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

const OldSignatureUrlHandler = () => {
  console.log('old signature url handler')
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);
  console.log(params);
  const decodedToken = window.atob(params.token);
  const urlParams = `/signature?token=${decodedToken}&template_id=${params.html_document_template_id}&signature_id=${params.assignment_id}`;
  history.push(urlParams);
  return null;
};

export default OldSignatureUrlHandler;

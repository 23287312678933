import React from "react";
import queryString from "query-string";
import Loading from "@components/ui/loading";
import ErrorPage from "@components/error-view";
import SignatureScreen from "@components/sign-document/sign-document";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import "./style.scss";

const TemplatePreview = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  console.log(params);

  const templateInfoApi = useQuery(["meta", params], async () => {
    const response = await fetch(
      `/api/template/download/${params.template_id}/meta.json?token=${params.token}`,
    );
    const data = await response.json();
    return { ...params, ...data };
  });

  const layoutApi = useQuery(["layout", params], async () => {
    const response = await fetch(
      `/api/template/download/${params.template_id}/layout.json?token=${params.token}`,
    );
    const data = await response.json();
    return data;
  });

  const templateInfo = templateInfoApi.data ?? {};

  if (layoutApi.isFetching || templateInfoApi.isFetching) return <Loading />;
  else if (layoutApi.isError || templateInfoApi.isError)
    return (
      <ErrorPage
        errorMessage={
          layoutApi.error?.message + "" + templateInfoApi.error?.message
        }
      />
    );

  return (
    <SignatureScreen
      templateInfo={templateInfo}
      layout={layoutApi.data ?? []}
      dmePreview
    />
  );
};

export default TemplatePreview;

import React from "react";
import { useSelector } from "react-redux";
import { selectTemplateInfo } from "@redux/template-info";
import useFormControlDrag from "@hooks/useFormControlDrag";
import FormControls from "./form-controls";
import TemplateWorkspace from "./template-workspace";

const TemplateEditor = () => {
  const dropZoneRef = React.useRef();
  const {
    onDragStart,
    onDragEnd,
    onDragOver,
    onDragLeave,
    onDrop,
    active,
    selectedControl,
  } = useFormControlDrag(dropZoneRef);
  return (
    <div className={`App ${selectedControl}`}>
      <FormControls onDragStart={onDragStart} onDragEnd={onDragEnd} />
      <TemplateWorkspace
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
        ref={dropZoneRef}
        active={active}
      />
    </div>
  );
};

const TemplateLoader = () => {
  const error = false;
  const isLoading = false;
  const templateInfo = useSelector(selectTemplateInfo);

  if (templateInfo.loaded) return <TemplateEditor />;
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        minHeight: 400,
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {isLoading && (
        <>
          <h3>Getting everything setup</h3>
        </>
      )}
      <div style={{ margin: 10, color: "red" }}>{error}</div>
    </div>
  );
};

export default TemplateLoader;

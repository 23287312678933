const elementDefaults = {
  TEXT: {
    attributes: {
      value: "",
      required: false,
      placeHolder: "",
      background: "none",
      underline: false,
    },
    size: {
      absoluteHeight: 20,
      absoluteWidth: 150,
      absoluteTop: 0,
      absoluteLeft: 0,
    },
  },
  LABEL: {
    attributes: { value: "", fontSize: 20, color: "#000000", underline: false },
    size: {
      absoluteHeight: 30,
      absoluteWidth: 150,
      absoluteTop: 0,
      absoluteLeft: 0,
    },
  },
  SIGNATURE: {
    attributes: {
      value: "",
      required: false,
      background: "none",
      underline: false,
    },
    size: {
      absoluteHeight: 50,
      absoluteWidth: 150,
      absoluteTop: 0,
      absoluteLeft: 0,
    },
  },
  DATE: {
    attributes: {
      value: "",
      required: false,
      background: "none",
      underline: false,
    },
    size: {
      absoluteHeight: 20,
      absoluteWidth: 150,
      absoluteTop: 0,
      absoluteLeft: 0,
    },
  },
  INITIALS: {
    attributes: {
      value: "",
      required: false,
      background: "none",
      underline: false,
    },
    size: {
      absoluteHeight: 20,
      absoluteWidth: 100,
      absoluteTop: 0,
      absoluteLeft: 0,
    },
  },
  CHECKBOX: {
    attributes: { value: "", label: "", background: "none" },
    size: {
      absoluteHeight: 20,
      absoluteWidth: 50,
      absoluteTop: 0,
      absoluteLeft: 0,
    },
  },
  RADIO_GROUP: {
    attributes: {
      value: "",
      required: false,
      elms: [
        { text: "option1", value: "option1" },
        { text: "option2", value: "option2" },
      ],
    },
    size: {
      absoluteHeight: 75,
      absoluteWidth: 100,
      absoluteTop: 0,
      absoluteLeft: 0,
    },
  },
};

export default elementDefaults;

import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Loading from "../ui/loading";

const validateTokenAndDob = async (token, { dateOfBirth }) => {
  const response = await fetch(`/api/auth/validate-token-and-dob`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token, dateOfBirth }),
  });
  if (!response.ok) throw response.text();
  const result = await response.json();
  return result;
};

function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}

const VerifyPatient = ({ setPatientIsVerified }) => {
  const location = useLocation();
  const [numberOfAttempts, setNumberOfAttempts] = React.useState(1);
  const tokenString = queryString.parse(location.search).token;
  const [dob, setDob] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div className="patient-signature-select no-print">
        <div className="patient-identifier-input">
          <div className="form-field legal-name-field">
            <label className="field-label">
              For security purposes, please confirm your date of birth.
            </label>
            <input
              className="input  date-input"
              type="date"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              name="patient_dob"
              required
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <button
            type="button"
            style={{ height: 40, width: 100 }}
            disabled={isLoading || !isDateValid(dob)}
            onClick={async () => {
              try {
                setIsLoading(true);
                setErrorMsg("");
                if (tokenString && tokenString.length > 0) {
                  const resultToken = await validateTokenAndDob(tokenString, {
                    dateOfBirth: dob,
                    numberOfAttempts,
                  });
                  if (resultToken) {
                    setPatientIsVerified(true);
                  }
                } else throw new Error("Invalid Link");
              } catch (err) {
                setErrorMsg(
                  err.message ?? "Birth dates do not match. Try again.",
                );
                setNumberOfAttempts((x) => x++);
              } finally {
                setIsLoading(false);
              }
            }}
          >
            Submit
          </button>
          {isLoading && <Loading />}
        </div>
        {errorMsg && <div>{errorMsg}</div>}
        {/* {numberOfAttempts > 1 && <div>Birth dates don't match. Try again.</div>} */}
      </div>
    </div>
  );
};

VerifyPatient.propTypes = {
  setPatientIsVerified: PropTypes.func.isRequired,
};

export default VerifyPatient;

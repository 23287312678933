import React from "react";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";

const ErrorPage = () => {
  const location = useLocation();
  const history = useHistory();
  const errorMessage = location.state?.errorMessage ?? "Something went wrong";
  const showBackBtn = location.state?.showBackBtn ?? true;
  return (
    <div
      className="error-page"
      style={{
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 10,
      }}
    >
      <h3 style={{ padding: 10 }}>Woops Sorry</h3>
      <div style={{ color: "red", padding: 5 }}>{errorMessage}</div>
      {showBackBtn && <button onClick={history.goBack}>Go Back</button>}
    </div>
  );
};

ErrorPage.propTypes = {
  errorMessage: PropTypes.string,
};

export default ErrorPage;
